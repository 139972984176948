export enum InvoiceError {
    TaxPredictionNotPossible = 'tax_prediction_not_possible',
    TaxComputationFailed = 'tax_computation_failed',
    PeriodCalculation = 'cannot_calculate_revenue_recognition_period',
    InvalidToken = 'invalid_token',
    TaxNotComputed = 'tax_not_computed_for_all_lines',
    IncorrectTwoInvoice = 'incorrect_two_invoice_error',
    InstallmentDiscrepancies = 'installment_discrepancies',
    CannotGenerateRevenueRecognitionForFuture = 'cannot_generate_revenue_recognition_for_dates_in_the_future',
    UnsupportedTopic = 'unsupported_topic',
    ReferenceNotLoaded = 'no_reference_for_invoice',
    NoFilesToSend = 'no_files_to_send',
    TwoApiInvoicesNotSendable = 'two_api_invoices_cant_be_mailed',
    DeelApiInvoicesNotSendable = 'deel_api_invoices_cant_be_mailed',
    BillingEntityWithoutOrganization = 'billing_entity_without_required_organization',
    InvoiceEntryReadOnly = 'invoice_entry_is_readonly',
    InvoiceEntryPeriodRequired = 'invoice_entry_period_required',
    InvoiceEntryPeriodNotSupported = 'invoice_entry_period_not_supported',
    InvoiceEntryProductCategoryRequired = 'invoice_entry_product_category_required',
    InvoiceEntryTypeNotValid = 'invoice_entry_type_not_valid',
    InvoiceEntryUpdateNotSupported = 'invoice_entry_update_not_supported',
    DeletePartnerInvoiceEntryNotAllowed = 'partner_invoice_entry_cannot_be_deleted',
    AddressUpdateInvalidTaxStatus = 'cannot_update_address_with_reported_tax',
    DeleteStoreAndReuseInvoiceEntry = 'cannot_delete_rental_store_and_reuse_invoice_entry',
    DifferentBillingEntityCurrency = 'different_billing_entity_currency',
    InvoiceLineAmountIsZero = 'invoice_line_amount_is_zero',
    BillingEntityNotActive = 'billing_entity_not_active',
    InconsistendInvoiceTimes = 'inconsistent_invoice_times',
    AlreadyInvoiced = 'already_invoiced',
    AlreadyHasTransaction = 'already_has_transaction',
    DeleteReadonlyEntryNotAllowed = 'readonly_entry_cannot_be_deleted',
    InvoiceEntryReportMaxRows = 'report_max_rows_exceeded',
    InvoiceEntryFilterOrgWithBillingEntity = 'organization_and_billing_entity_specified',
}
