import { invert, kebabCase } from 'lodash-es';

import { ApiError as SharedError } from '@hofy/api-shared';

import { AddonError } from './addon/service/AddonError';
import { AssignmentError } from './assignment/service/AssignmentError';
import { BetaFeatureError } from './betaFeature/service/BetaFeatureError';
import { ByodOrderError } from './byodOrder/service/ByodOrderError';
import { CollectionRequestError } from './collectionRequest/service/CollectionRequestError';
import { ContractError } from './contract/service/ContractError';
import { ContractSettingError } from './contractSetting/service/ContractSettingError';
import { DataErasureError } from './dataErasure/service/DataErasureError';
import { ExchangeRateError } from './exchangeRate/service/ExchangeRateError';
import { FulfilmentError } from './fulfilment/service/FulfilmentError';
import { FulfilmentCountrySettingError } from './fulfilmentCountrySetting/service/FulfilmentCountrySettingError';
import { HardwarePolicyError } from './hardwarePolicy/service/HardwarePolicyError';
import { HostError } from './host/service/HostError';
import { HrisError } from './hris/service/HrisError';
import { InspectionError } from './inspection/service/InspectionError';
import { InvoiceError } from './invoice/service/InvoiceError';
import { IssueError } from './issue/service/IssueError';
import { ItemError } from './item/service/ItemError';
import { ItPolicyError } from './itPolicy/service/ItPolicyError';
import { JournalError } from './journal/service/JournalError';
import { OrderError } from './order/service/OrderError';
import { OrganizationError } from './organization/service/OrganizationError';
import { PaymentError } from './payment/service/PaymentError';
import { ProductError } from './product/service/ProductError';
import { PurchaseOrderError } from './purchaseOrder/service/PurchaseOrderError';
import { RepairError } from './repair/service/RepairError';
import { RepaymentPlanError } from './repaymentPlan/service/RepaymentPlanError';
import { SalesOrderError } from './salesOrder/service/SalesOrderError';
import { ShipmentError } from './shipment/service/ShipmentError';
import { ShipmentTrackingError } from './shipmentTracking/service/ShipmentTrackingError';
import { useAdminApiI18n } from './useAdminApiI18n';
import { UserError } from './user/service/UserError';
import { WarehouseError } from './warehouse/service/WarehouseError';
import { ZendeskError } from './zendesk/service/ZendeskError';

export type ApiError =
    | SharedError
    | ZendeskError
    | WarehouseError
    | UserError
    | ShipmentTrackingError
    | ShipmentError
    | SalesOrderError
    | RepaymentPlanError
    | RepairError
    | PurchaseOrderError
    | ProductError
    | PaymentError
    | OrganizationError
    | OrderError
    | JournalError
    | ItemError
    | ItPolicyError
    | IssueError
    | InvoiceError
    | InspectionError
    | HrisError
    | HostError
    | HardwarePolicyError
    | FulfilmentCountrySettingError
    | FulfilmentError
    | ExchangeRateError
    | DataErasureError
    | ContractSettingError
    | ContractError
    | CollectionRequestError
    | ByodOrderError
    | BetaFeatureError
    | AssignmentError
    | AddonError;

export const apiErrorNames: Record<string, string> = [
    SharedError,
    ZendeskError,
    WarehouseError,
    UserError,
    ShipmentTrackingError,
    ShipmentError,
    SalesOrderError,
    RepaymentPlanError,
    RepairError,
    PurchaseOrderError,
    ProductError,
    PaymentError,
    OrganizationError,
    OrderError,
    JournalError,
    ItemError,
    ItPolicyError,
    IssueError,
    InvoiceError,
    InspectionError,
    HrisError,
    HostError,
    HardwarePolicyError,
    FulfilmentCountrySettingError,
    FulfilmentError,
    ExchangeRateError,
    DataErasureError,
    ContractSettingError,
    ContractError,
    CollectionRequestError,
    ByodOrderError,
    BetaFeatureError,
    AssignmentError,
    AddonError,
]
    .map(enumEntity => invert(enumEntity))
    .reduce((accumulator, current) => ({ ...accumulator, ...current }), {});

export const useTrApiError = () => {
    const { trParts } = useAdminApiI18n();
    return {
        trTitle: (v: ApiError) => trParts('api-error', kebabCase(apiErrorNames[v]), 'title'),
        trMessage: (v: ApiError) => trParts('api-error', kebabCase(apiErrorNames[v]), 'message'),
    };
};
