export const addonErrorI18n = {
    'api-error.invalid-addon-billing-frequency.title': 'Addon has invalid billing frequency',
    'api-error.invalid-addon-billing-frequency.message': 'Cannot perform this operation',
    'api-error.missing-addon-id.title': 'Addon id missing',
    'api-error.missing-addon-id.message': 'Cannot perform this operation',
    'api-error.addon-not-found.title': 'Addon not found',
    'api-error.addon-not-found.message': 'Cannot perform this operation',
    'api-error.invalid-addon-length.title': 'Invalid addon length',
    'api-error.invalid-addon-length.message': 'Cannot perform this operation',
    'api-error.public-addon-cannot-be-inactive.title': 'Public addon cannot be inactive',
    'api-error.public-addon-cannot-be-inactive.message': 'Cannot perform this operation',
    'api-error.invalid-addons-for-acquisition-type.title': 'Invalid addons for acquisition type',
    'api-error.invalid-addons-for-acquisition-type.message': 'Cannot perform this operation',
    'api-error.private-addon-cannot-have-blank-duration.title': 'Private addon cannot have blank duration',
    'api-error.private-addon-cannot-have-blank-duration.message': 'Cannot perform this operation',
    'api-error.private-addon-cannot-have-blank-payment-schema.title':
        'Private addon cannot have blank payment schema',
    'api-error.private-addon-cannot-have-blank-payment-schema.message': 'Cannot perform this operation',
};
